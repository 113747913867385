/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { useTranslation, useAncillaryCardsData } from "hooks";
import * as profileRoutes from "Profile/profileRoutes";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { ChatIcon, QuickLinks } from "components";
import {
  additionalRequestsPreppers,
  mostImportantPreppers,
  tripPurposePreppers,
  roomGuestsPreppers,
} from "Profile/utils/updateUpcomingTripMappers";
import { ErrorMessage, Button, Link as FSLink } from "Profile/components";
import { AncillaryBookingCards } from "Profile/components/AncillaryBookingCards";
import ThemeContextProvider, { THEMES } from "contexts/ThemeContext";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { selectProperty } from "store/properties";
import {
  leadWithCareLinkClicked,
  contextualLinkClick,
} from "store/analyticEvents";
import { selectGlobalSettings } from "store/globalSettings";
import { selectIsUserLoggedIn } from "store/profile/profile.selectors";
import ToastNotification from "components/ToastNotification";
import { getStaticSiteProfileMyStaysLink } from "utils/externalLinks";
import Guests from "./Guests";
import Request from "./Request";
import ReservationHighlights from "./ReservationHighlights";
import SpecialRequest from "./SpecialRequest";
import TripCancellation from "./TripCancellation";
import TripDetails from "./TripDetails";
import UpcomingTripHeader from "./UpcomingTripHeader";
import UpcomingTripPreferences from "./UpcomingTripPreferences";
import YourStayPerfected from "./YourStayPerfected";

export default function UpcomingTrip({
  phoneNumberPrimary,
  allowCancellation,
  cancelByDate,
  canChangeBooking,
  changeArrivalTime,
  comment,
  employeeRate,
  goToChangeTravelDates,
  goToEstimatedTotal,
  goToTripCancellation,
  hasErrors,
  hotelCode,
  isTrimmed = false,
  phoneNumber,
  propertyContent,
  reservationHighlights,
  reservationId,
  showSavingSpinner,
  upcomingTrip,
  updateAdditionalRequests,
  updateMostImportantThing,
  updateRoomGuests,
  updateTripPurpose,
  youCanBookMessage,

  // WebChat
  isWebChatEnabled,
  openWebChat,

  // Welcome Amentities
  canRequestWelcomeAmenities,
  goToAmenityRequests,

  // Arrival Transportation
  goToArrivalTransportationView,
  hasTransportation,
  arrivalTransportation,
  canRequestTransportation,

  isUnauthenticated = false,
}) {
  const { t, locale, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { globalHealthAndSafetyLink, enableProfileOnWww } =
    useSelector(selectGlobalSettings);
  const property = useSelector(selectProperty(hotelCode));

  const { propertyStatusLink, propertyTravelAdvisoryLink } = property ?? {};

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const { ancillaryCardsData } = useAncillaryCardsData({
    hotelCode,
    reservationId,
    upcomingTrip,
    transportationEnabled: canRequestTransportation && !hasTransportation,
    goToArrivalTransportationView,
    amenitiesEnable: canRequestWelcomeAmenities,
    goToAmenityRequests,
  });

  const itineraryPath =
    !isUnauthenticated && isUserLoggedIn
      ? profileRoutes.profileBookingItineraryPath.to({
          locale,
          hotelCode,
          reservationId,
        })
      : authenticationRoutes.unauthenticatedBookingItineraryPath.to({
          locale,
          hotelCode,
          reservationId,
        });

  const quickLinks = [
    globalHealthAndSafetyLink && {
      href: globalHealthAndSafetyLink,
      icon: "/images/profiles_2018/Health_&_Safety.svg",
      title: t("Enhanced Health & Safety"),
      description: t(
        "Learn more about Lead with Care, our global health & safety program"
      ),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "Enhanced Health & Safety",
          })
        );
      },
    },
    propertyStatusLink && {
      href: propertyStatusLink,
      icon: "/images/profiles_2018/property_info.svg",
      title: t("Property Information"),
      description: t("Check for latest property related updates"),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "Property Information",
          })
        );
      },
    },
    propertyTravelAdvisoryLink && {
      href: propertyTravelAdvisoryLink,
      icon: "/images/profiles_2018/Travel_Advisory.svg",
      title: t("Travel Advisory"),
      description: t("All you need to know before your travel."),
      onClick: () => {
        dispatch(
          leadWithCareLinkClicked({
            linkName: "Travel Advisory",
          })
        );
      },
    },
  ].filter(Boolean);

  const handleYouCanBookClick = (evt) => {
    if (evt.target.href) {
      evt.preventDefault();
      dispatch(
        contextualLinkClick({
          kind: evt.target.dataset.interactionDetail,
          hotelCode,
          booking: upcomingTrip,
        })
      );
      window.open(evt.target.href, "_blank");
    }
  };

  return (
    <ThemeContextProvider theme={THEMES.LIGHT}>
      <ToastNotification />
      <div
        className={`layout--profile ${i18n.language} ${i18n.dir(
          i18n.language
        )}`}
      >
        <div className="layout--profile-fullscreen layout--profile-fullscreen--light view--profile-upcoming-trip">
          <div className="layout--profile-fullscreen__top-bar">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {!isTrimmed &&
                    (enableProfileOnWww ? (
                      <FSLink
                        className="btn-back"
                        kind="unstyled"
                        href={getStaticSiteProfileMyStaysLink(locale)}
                      >
                        <span>{t("Back")}</span>
                      </FSLink>
                    ) : (
                      <Link
                        className="btn-back"
                        to={profileRoutes.profilePath.to({
                          locale,
                        })}
                      >
                        <span>{t("Back")}</span>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="layout--profile-fullscreen__body">
            {hasErrors && (
                // AJD: TODO - Need to determine what text to display here
                <div className="container">
                  <ErrorMessage>
                    {t("Error")}:{" "}
                    {t(
                      "We were unable to modify your reservation. Please contact us for further information."
                    )}
                  </ErrorMessage>
                </div>
              )}

              <UpcomingTripHeader
                propertyContent={propertyContent}
                isWebChatEnabled={isWebChatEnabled}
                startSmooch={openWebChat}
                itineraryPath={itineraryPath}
              />
              <div
                className={classNames("fs-trip-details-container", {
                  "alternative-auth": isTrimmed,
                })}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9 offset-lg-1">
                      {isWebChatEnabled &&
                        locale === "en" &&
                        youCanBookMessage && (
                          <div className="cwu-container">
                            <p
                              className="cwu-text"
                              dangerouslySetInnerHTML={{
                                __html: youCanBookMessage,
                              }}
                              onClick={handleYouCanBookClick}
                            />
                            <div className="cwu-container__actions">
                              <Button onClick={openWebChat}>
                                <div className="web-chat-button">
                                  <ChatIcon />
                                </div>
                                {t("Chat With Us")}
                              </Button>

                              <FSLink to={itineraryPath} kind="secondary">
                                {t("View Itinerary")}
                              </FSLink>
                            </div>
                          </div>
                        )}

                      <TripDetails
                        canChangeDates={
                          !isTrimmed && canChangeBooking && !employeeRate
                        }
                        onClick={goToChangeTravelDates}
                        onSubmit={changeArrivalTime}
                        propertyContent={propertyContent}
                        upcomingTrip={upcomingTrip}
                        showSavingSpinner={showSavingSpinner}
                        isUnauthenticated={isUnauthenticated}
                      />

                      {!canChangeBooking && (
                        <div
                          className="fs-trip__no-changes"
                          dangerouslySetInnerHTML={{
                            __html: phoneNumber
                              ? t(
                                  "This reservation cannot be changed online. Please contact us at {0} for assistance.",
                                  [phoneNumber]
                                )
                              : t(
                                  "This reservation cannot be changed online. Please contact us {0} for assistance.",
                                  [""]
                                ),
                          }}
                        />
                      )}

                      <AncillaryBookingCards
                        title={t("Create your perfect stay")}
                        subtitle={t(
                          "Request our curated services and experiences so you can relax when you get here."
                        )}
                        ancillaryCardsData={ancillaryCardsData}
                      />

                      {hasTransportation && (
                        <Request
                          title={t("Arrival Transportation")}
                          description={t(
                            "View your transportation request from here."
                          )}
                          actions={
                            <FSLink
                              to={`${itineraryPath}#${arrivalTransportation.id}`}
                            >
                              {t("View in Itinerary")}
                            </FSLink>
                          }
                        />
                      )}

                      {!canRequestTransportation && !hasTransportation && (
                        <Request
                          title={t("Arrival Transportation")}
                          description={{
                            html: t(
                              "We’re sorry but the arrival transportation service cannot be arranged at this time.  Please call us at {0} or chat with us to complete this request.",
                              [
                                `<a href="tel:${phoneNumberPrimary}">${phoneNumberPrimary}</a>`,
                              ]
                            ),
                          }}
                          actions={
                            isWebChatEnabled && (
                              <Button kind="link" onClick={openWebChat}>
                                {t("Chat with us")}
                              </Button>
                            )
                          }
                        />
                      )}

                      <Guests
                        guests={roomGuestsPreppers.receive({
                          comment,
                          booking: upcomingTrip,
                        })}
                        onSubmit={updateRoomGuests}
                        allowCancellation={canChangeBooking}
                        showSavingSpinner={showSavingSpinner}
                        isUnauthenticated={isUnauthenticated}
                      />

                      <UpcomingTripPreferences
                        showPreferences={!isTrimmed}
                        defaultValues={tripPurposePreppers.receive(comment)}
                        onSubmit={updateTripPurpose}
                        allowCancellation={canChangeBooking}
                        showSavingSpinner={showSavingSpinner}
                        isUnauthenticated={isUnauthenticated}
                      />

                      {canChangeBooking ? (
                        <>
                          <SpecialRequest
                            label="Special Requests"
                            defaultValues={additionalRequestsPreppers.receive(
                              comment
                            )}
                            onSubmit={updateAdditionalRequests}
                            fieldName="additionalRequests"
                            showSavingSpinner={showSavingSpinner}
                            isUnauthenticated={isUnauthenticated}
                          />

                          <SpecialRequest
                            className="fs-trip__most-important-thing"
                            label="Most Important Thing For Your Stay"
                            defaultValues={mostImportantPreppers.receive(
                              comment
                            )}
                            onSubmit={updateMostImportantThing}
                            fieldName="mostImportant"
                            showSavingSpinner={showSavingSpinner}
                            isUnauthenticated={isUnauthenticated}
                          />
                        </>
                      ) : (
                        <>
                          <div className="fs-your-preferences__preference fs-your-preferences__preference--special-requests">
                            <div className="fs-your-preferences__preference__title">
                              <h3>{t("Special Requests")}</h3>
                            </div>
                            {additionalRequestsPreppers.receive(comment)
                              ?.additionalRequests && (
                              <ul className="fs-your-preferences__preference__selected-preferences">
                                <li>
                                  {additionalRequestsPreppers.receive(comment)
                                    ?.additionalRequests || ""}
                                </li>
                              </ul>
                            )}
                          </div>
                          <div className="fs-your-preferences__preference fs-your-preferences__preference--special-requests">
                            <div className="fs-your-preferences__preference__title">
                              <h3>{t("Most Important Thing For Your Stay")}</h3>
                            </div>
                          </div>
                        </>
                      )}

                      {!isTrimmed &&
                        allowCancellation &&
                        goToTripCancellation && (
                          <TripCancellation
                            onClick={goToTripCancellation}
                            cancelByDate={cancelByDate}
                            isUnauthenticated={isUnauthenticated}
                          />
                        )}

                      {quickLinks.length > 0 && (
                        <div className="fs-trip__quick-links">
                          <QuickLinks links={quickLinks} />
                        </div>
                      )}
                    </div>
                    <ReservationHighlights
                      onClick={goToEstimatedTotal}
                      highlights={reservationHighlights}
                    />
                  </div>
                </div>
              </div>

              <YourStayPerfected
                title={t("Your Stay, Perfected")}
                content={t(
                  "Plan your trips and manage your itinerary, any time and on any device."
                )}
                ctaPath={itineraryPath}
                ctaLabel={t("plan now")}
                image="/images/profiles_2018/your-stay-perfected.png"
              />
          </div>
        </div>
      </div>

      {showSavingSpinner && <BookingFlowLoadingIndicator />}
    </ThemeContextProvider>
  );
}
