import { push } from "connected-react-router";
import { map, catchError, switchMap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import get from "lodash/get";

import { authenticationRoutes } from "Authentication";
import { bookingFlowRoutes } from "BookingFlow";
import { profileRoutes } from "Profile";
import { getPropertyContent$ } from "api/content";
import {
  fetchPropertyContentFulfilled,
  fetchPropertyContentFailed,
} from "store/propertyContent/propertyContent.slice";
import { fetchMemberDetailsFulfilled } from "store/memberDetails";
import { fetchBookingHistoryFulfilled } from "store/bookingHistory";
import { fetchProfileWithBookingHistory$ } from "store/profile/epics/fetchProfile";
import {
  fetchProfileFailed,
  fetchProfileFulfilled,
} from "store/profile/profile.slice";
import { setShouldOpen as setToastNotificationShouldOpen } from "store/toastNotification";
import { loadingRedirect } from "store/appStatus/appStatus.slice";
import { redirectTo } from "store/signIn/signIn.slice";
import { getStaticSiteProfileMyStaysLink } from "utils/externalLinks";
import { selectGlobalSettings } from "store/globalSettings";

const maybeFetchProfile = ({ isSignedIn, locale }) =>
  isSignedIn
    ? of(EMPTY)
    : fetchProfileWithBookingHistory$({ locale }).pipe(
        catchError(({ response = {} }) => of({
            actions: [
              fetchProfileFailed({
                apiErrors: response?.apiErrors || [],
                supplierErrors: response?.supplierErrors || [],
              }),
            ],
          }))
      );

const maybeFetchPropertyContent = ({ locale, propertyCode }) => !propertyCode
    ? of(EMPTY)
    : getPropertyContent$({
        locale,
        hotelCode: propertyCode,
      }).pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.error) {
            throw ajaxResponse.error;
          }
          return {
            propertyUrlName: ajaxResponse?.urlName,
            actions: [
              fetchPropertyContentFulfilled({
                propertyContent: ajaxResponse,
                propertyCode,
              }),
            ],
          };
        }),

        catchError((error) => of({
            actions: [
              fetchPropertyContentFailed({
                error,
                propertyCode,
              }),
              push({
                pathname: bookingFlowRoutes.planYourStay.to({ locale }),
                state: { error },
              }),
            ],
          }))
      );

// if user is not signed in will fetch profile to see if user is signed in
// if user is signed in will redirect them to the profiles version of upcomign trip view
// if a property code is present will fetch the property content otherwise will continue
// if fetching property content fails, user is redirected to plan-your-stay view, the push event includes the error, though not currently used
export default function unauthenticatedBookingPathActions({
  locale,
  hotelCode,
  reservationId,
  ancillaryName,
  isSignedIn,
  state,
}) {
  return maybeFetchProfile({ isSignedIn, locale }).pipe(
    switchMap(({ profile, bookingHistory, memberDetails, actions = [] }) => {
      const isSignedInNow = Boolean(isSignedIn || profile?.id);
      const bookingSummaries =
        bookingHistory?.bookingSummaries ||
        get(state, ["bookingHistory", "data", "bookingSummaries"], []);
      const shouldRedirectToSignedInUpcomingTripView = () => bookingSummaries
          .flatMap(({ hotelProducts }) =>
            hotelProducts.map((hotelProduct) => hotelProduct.reservationId)
          )
          .includes(reservationId);

      const userSignedInActions = [];
      const isSignedInAndHasMatchingTrip =
        isSignedInNow &&
        hotelCode &&
        reservationId &&
        shouldRedirectToSignedInUpcomingTripView();
      if (isSignedInAndHasMatchingTrip) {
        userSignedInActions.push(
          push(
            profileRoutes.profileBookingPath.to({
              hotelCode,
              reservationId,
              locale,
            })
          )
        );
      } else if (isSignedInNow) {
        const { enableProfileOnWww } = selectGlobalSettings(state);
        if (enableProfileOnWww) {
          return {
            actions: [
              loadingRedirect(),
              redirectTo({
                afterSignInRedirectTo: getStaticSiteProfileMyStaysLink(locale),
              }),
            ],
          };
        }
        if (
          !state?.router?.location?.pathname?.includes("/find-reservations") &&
          hotelCode &&
          reservationId
        ) {
          userSignedInActions.push(
            setToastNotificationShouldOpen({
              shouldOpen: "onNextView",
              notificationText:
                "Sorry, we cannot find the reservation on this profile.",
            })
          );
        }
        userSignedInActions.push(
          push(
            `${profileRoutes.profilePath.to({ locale })}${
              bookingSummaries.length > 0 ? "#upcoming-trips" : ""
            }`
          )
        );
      }

      return maybeFetchPropertyContent({
        propertyCode: hotelCode,
        locale,
      }).pipe(
        map(({ propertyUrlName, actions: propertyContentActions = [] }) => {
          if (
            isSignedInAndHasMatchingTrip &&
            propertyUrlName &&
            ancillaryName
          ) {
            return {
              actions: [
                loadingRedirect(),
                redirectTo({
                  afterSignInRedirectTo:
                    authenticationRoutes.unauthenticatedBookingAncillaryPath.to(
                      { propertyUrlName, ancillaryName, locale }
                    ),
                }),
              ],
            };
          }

          return {
            actions: [
              ...actions,
              ...propertyContentActions,
              memberDetails && fetchMemberDetailsFulfilled(memberDetails),
              bookingHistory && fetchBookingHistoryFulfilled(bookingHistory),
              profile && fetchProfileFulfilled(profile),
              ...userSignedInActions,
            ].filter(Boolean),
          };
        })
      );
    })
  );
}
